import Contact from "../Contact";
import styles from "./index.module.scss";

const Fallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <div className={styles.fallback}>
      <h1>
        😭😂😩👀😅🤣😱<p>糟糕，页面顶不住了</p>
      </h1>
      <div className={styles.content}>
        <span
          onClick={() => {
            window.location.reload();
          }}
        >
          🙈刷新页面试试看？😬
        </span>
        <img src={`${require("@/assets/fallback.jpg")}`} alt="fallback" />

        <span>👇刷新都不行？请联系我🫡</span>
      </div>
      <Contact />
    </div>
  );
};

export default Fallback;
