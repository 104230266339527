import { getCLS, getFID, getFCP, getLCP, getTTFB, Metric } from "web-vitals";
import { sendPerformance } from "./utils/dig";

const reportWebVitals = () => {
  const queue = new Set();
  function addToQueue(metric: Metric) {
    queue.add(metric);
  }

  function flushQueue() {
    if (queue.size > 0) {
      const metrics: any = {};
      [...queue].forEach((item: any) => {
        const { name, value } = item;
        metrics[name] = value;
      });

      sendPerformance(metrics);
      queue.clear();
    }
  }
  getCLS(addToQueue);
  getFID(addToQueue);
  getFCP(addToQueue);
  getLCP(addToQueue);
  getTTFB(addToQueue);

  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      flushQueue();
    }
  });

  window.addEventListener("pagehide", flushQueue);
};

export default reportWebVitals;
