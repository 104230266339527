import { post } from "./request";

// 创建浏览器指纹
function createFingerprint() {
  // 简化示例，实际项目会包含更多更复杂的属性
  const fingerprintComponents = [
    navigator.userAgent,
    window.screen.width + "x" + window.screen.height,
    navigator.language,
  ];

  // 对组件做哈希处理，生成一个较短的标识符
  const hashedFingerprint = hashComponents(fingerprintComponents.join(" "));
  return hashedFingerprint;
}

// 使用base64编码作为简化的哈希
function hashComponents(components: string) {
  return btoa(components);
}

//发送UV
export function sendUV() {
  const fingerprint = createFingerprint();
  sendDigData({ type: "uv", fingerprint });
}

//发送PV
export const sendPV = (page_url: string) => {
  const fingerprint = createFingerprint();
  sendDigData({
    type: "pv",
    page_url,
    fingerprint,
  });
};

// 发送页面性能数据

// const page_url = window.location.href;
// const load_time = ""; // 页面加载时间（秒）Load事件触发的时间
// const dom_content_loaded_time = ""; // DOM交互时间（秒），DOMContentLoaded事件触发的时间
// const time_to_first_byte = ""; // 首字节时间（TTFB，秒）
// const first_contentful_paint = ""; //首次内容绘制时间（FCP，秒）
// const largest_contentful_paint = ""; //最大的内容绘制时间（LCP，秒）
// const first_input_delay = ""; //首次输入延迟时间（FID，毫秒）
// const cumulative_layout_shift = ""; //累积布局偏移分数（CLS）
interface PerformanceData {
  load_time?: number;
  dom_content_loaded_time?: number;
  TTFB?: number;
  FCP?: number;
  LCP?: number;
  FID?: number;
  CLS?: number;
}

const calculateAdditionalMetrics = () => {
  if (performance.getEntriesByType("navigation").length > 0) {
    const navEntry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;
    const dom_content_loaded_time = navEntry.domContentLoadedEventEnd;
    const load_time = navEntry.loadEventEnd;
    return {
      load_time,
      dom_content_loaded_time,
    };
  }
};

export const sendPerformance = (performanceData: PerformanceData) => {
  const rest = calculateAdditionalMetrics();
  const data = {
    ...performanceData,
    ...rest,
  };
  const fingerprint = createFingerprint();
  sendDigData({
    type: "performance",
    TTFB: null,
    FCP: null,
    LCP: null,
    FID: null,
    CLS: null,
    ...data,
    fingerprint,
  });
};

const sendDigData = (data: any) => {
  // 发送数据到后端
  process.env.NODE_ENV !== "development" && post("/analytics/dig", data);
};
