export const getTimeValue = (): number => {
  const now = new Date();
  const hour = now.getHours();

  if (hour >= 6 && hour < 10) {
    return 1;
  } else if (hour >= 10 && hour < 17) {
    return 2;
  } else if (hour >= 17 && hour < 19) {
    return 3;
  } else {
    return 4;
  }
};

export const getRadomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export function generateDarkColor(): string {
  const r = Math.floor(Math.random() * 101);
  const g = Math.floor(Math.random() * 101);
  const b = Math.floor(Math.random() * 101);

  // 如果颜色太亮，则重新生成
  if (r + g + b > 200) {
    return generateDarkColor();
  }

  return `rgb(${r}, ${g}, ${b})`;
}

export function createGradient() {
  const startColor = generateDarkColor();
  let endColor = generateDarkColor();

  while (startColor === endColor) {
    // 避免开始和结束颜色相同，重新生成结束颜色
    endColor = generateDarkColor();
  }

  const gradientDirection = ["to right", "to bottom", "to top left", "to bottom right"][
    Math.floor(Math.random() * 4)
  ]; // 随机方向

  return `linear-gradient(${gradientDirection}, ${startColor}, ${endColor})`;
}

export const debounce = (func: Function, wait: number = 300) => {
  let timeId: NodeJS.Timeout;
  return (...args: any) => {
    if (timeId) clearTimeout(timeId);
    timeId = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

//返回当前的年月日，格式为YYYY-MM-DD
export const getNowDate = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
};

//判断http链接是否有效
export const isHttpUrl = (url: string): boolean => {
  return url.indexOf("http://") === 0 || url.indexOf("https://") === 0;
};

//字符串s超过个字符，把中间内容替换为...
export const truncateString = (s: string, maxLength: number) => {
  // 如果字符串长度小于等于最大长度，则直接返回原字符串
  if (s.length <= maxLength) {
    return s;
  }

  // 计算截取的起始点
  const start = Math.floor((maxLength - 3) / 2);

  // 截取并添加省略号...
  return s.slice(0, start) + "..." + s.slice(-Math.ceil(maxLength / 2));
};
