import axios from "axios";

let service = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? "http://localhost:8989" : "https://mocaii.cn/server",
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers["authorization"] = token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

interface Resopnse {
  code: number;
  message: string;
  data?: any;
  token?: string;
}

service.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    const { token, message } = data as Resopnse;
    switch (status) {
      case 200:
        if (token) {
          localStorage.setItem("token", token);
        }
        return response.data;
      case 401:
        alert(message);
        break;
      default:
        return response.data;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const get = (url: string, params = {}) => {
  return service({
    url,
    params,
    method: "get",
  });
};

export const post = (url: string, data = {}) => {
  const optoins = {
    url,
    data: data,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return service(optoins);
};

export const put = (url: string, data = {}) => {
  return service({
    url,
    data,
    method: "put",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
};
