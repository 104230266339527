import React from "react";
import styles from "./index.module.scss";

function Footer() {
  return (
    <footer className={styles.footer}>
      <a href="http://beian.miit.gov.cn" target="_blank" rel="noreferrer">
        粤ICP备2024207943号
      </a>
    </footer>
  );
}

export default Footer;
