import { createBrowserRouter, Outlet } from "react-router-dom";
import { lazy } from "react";
import Layout from "@/components/Layout";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "@/components/Fallback";

interface IRoute {
  path: string;
  filePath: string;
}
const routeConfig: IRoute[] = [
  {
    path: "/",
    filePath: "Home",
  },
  {
    path: "/games",
    filePath: "Games",
  },
  {
    path: "/notes",
    filePath: "Notes",
  },
  {
    path: "/resourceDetail",
    filePath: "ResourceDetail",
  },
  {
    path: "/bookList",
    filePath: "BookList",
  },
  {
    path: "daily",
    filePath: "Daily",
  },
  {
    path: "/resources",
    filePath: "Resources",
  },
  {
    path: "/news",
    filePath: "News",
  },
  {
    path: "/profile",
    filePath: "PersonalProfile",
  },

  // {
  //   path: "/chat",
  //   filePath: "Chat",
  // },
  // {
  //   path: "/ai",
  //   filePath: "AI",
  // },
  // {
  //   path: "prompt",
  //   filePath: "Prompt",
  // },
  // {
  //   path: "solarSystem",
  //   filePath: "SolarSystem",
  // },
];

const children = routeConfig.map((item) => {
  const Element = lazy(() => import(`@/pages/${item.filePath}`));
  return {
    path: item.path,
    element: <Element />,
  };
});

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary FallbackComponent={Fallback}>
        <Layout>
          <Outlet />
        </Layout>
      </ErrorBoundary>
    ),
    children: children,
  },
]);
