import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

type Position = "static" | "absolute" | "fixed";
const Avator = ({ position }: { position?: Position }) => {
  return (
    <Link to="/" className={styles.avatar} style={{ position: position }}>
      <img src={require(`@/assets/cat.jpg`)} alt="" />
    </Link>
  );
};

export default Avator;
