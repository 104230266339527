import React from "react";
import styles from "./index.module.scss";
import { Popover } from "antd";
const Contact = () => {
  return (
    <div className={styles.contact}>
      <a href="https://github.com/mocaii" target="_blank" rel="noreferrer">
        <img src={require("@/assets/icons/github.png")} alt="github" />
      </a>
      <a href="mailto:mocaimei@foxmail.com" className={styles.email}>
        <img src={require("@/assets/icons/email.png")} alt="邮箱" />
      </a>
      <Popover
        content={
          <img src={require("@/assets/QRcode.jpg")} alt="wechat" className={styles.qrcode} />
        }
      >
        <img src={require("@/assets/icons/wechat.png")} alt="微信" />
      </Popover>
    </div>
  );
};

export default Contact;
