import React from "react";
import { useTheme } from "@/components/ThemeProvider";
import styles from "./index.module.scss";
const ThemeToggle = () => {
  const { theme, setTheme } = useTheme();
  return (
    <div className={styles.theme} title="theme">
      {theme === "dark" ? (
        <img src={require("@/assets/icons/sun.png")} alt="" onClick={() => setTheme("light")} />
      ) : (
        <img src={require("@/assets/icons/moon.png")} alt="" onClick={() => setTheme("dark")} />
      )}
    </div>
  );
};

export default ThemeToggle;
