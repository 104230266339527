import React, { useEffect } from "react";
import styles from "./index.module.scss";
import Header from "../Header";
import { useTheme } from "../ThemeProvider";
import { getRadomNumber } from "@/utils/tool";
import Footer from "../Footer";
import { sendPV } from "@/utils/dig";
import { useLocation } from "react-router-dom";

const bg = `url(${require(`@/assets/bg/${getRadomNumber(1, 4)}.png`)})`;

const Layout = ({ children }: any) => {
  const { theme } = useTheme();
  const location = useLocation();
  useEffect(() => {
    sendPV(location.pathname);
  }, [location.pathname]);

  return (
    <div
      className={styles.layout}
      style={
        theme === "light"
          ? //  && location.pathname === "/"
            {
              backgroundImage: bg,
              // transition: "transform 0.5s ease-out",
              // backgroundImage: createGradient(),
            }
          : {}
      }
    >
      <Header />
      <div className={styles.contentContainer}> {children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
