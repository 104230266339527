import React from "react";
import styles from "./index.module.scss";
import Icon from "@/components/Icon";
import { useNavigate } from "react-router-dom";

const navList = [
  {
    title: "资源收集",
    path: "/resources?id=1",
  },

  {
    title: "最新资讯",
    path: "/news",
  },
  {
    title: "书单推荐",
    path: "/bookList",
  },
  {
    title: "学习笔记",
    path: "/notes",
  },
];

/**
 *
 * @returns {React.ReactElement}
 * 侧边导航
 */

const ToolNav = () => {
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.ToolNav} ${
        window.location.pathname !== "/" ? styles.ToolNavForHeader : ""
      }`}
    >
      {navList.map((item, index: number) => (
        <div
          // className={`${styles.ToolNavItem} ${index === curNav ? styles.ToolNavItemActive : ""}`}
          className={styles.ToolNavItem}
          key={item.title}
          onClick={() => {
            // changeNav(index);

            if (item.title === "学习笔记") {
              window.open("https://blog.mocaii.cn", "_blank");
            } else {
              navigate(item.path);
            }
          }}
        >
          <Icon index={index} />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

export default ToolNav;
