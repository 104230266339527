import React from "react";
import styles from "./index.module.scss";
import Avatar from "@/components/Avatar";
import ToolNav from "../ToolNav";
import ThemeToggle from "../ThemeToggle";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <header className={styles.header} style={isHome ? { backgroundColor: "unset" } : {}}>
      <div className={styles.avatarAndTitle}>
        <Avatar />
        <h1>mocai</h1>
      </div>
      {!isHome && <ToolNav />}
      <ThemeToggle />
    </header>
  );
};

export default Header;
